<template>
  <boxed-section title="Conditions">
    <conditions-image/>
  </boxed-section>
</template>

<script>
  import ConditionsImage from "@/components/ConditionsImage.vue";

  export default {
    name: "Conditions",
    components: {
      ConditionsImage,
    },
  }
</script>

<style>
</style>
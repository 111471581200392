import { render, staticRenderFns } from "./CharacterSheet.vue?vue&type=template&id=538f7f61&scoped=true&"
import script from "./CharacterSheet.vue?vue&type=script&lang=js&"
export * from "./CharacterSheet.vue?vue&type=script&lang=js&"
import style0 from "./CharacterSheet.vue?vue&type=style&index=0&id=538f7f61&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "538f7f61",
  null
  
)

export default component.exports